{
  "appName": "Parfois",
  "EGP": "EGP",
  "addToCart": "Add to cart",
  "validation.required": "This field is required",
  "validation": {
    "required": "This field is required",
    "email": "Please enter a valid email address",
    "phone": "Please enter a valid phone number",
    "number": "Please enter a valid number",
    "invalidEmailOrPhone": "Please enter a valid email or phone number",
    "password": "Minimum password is 8 characters",
    "invalidFullName": "Please enter a valid full name",
    "invalidPhone": "Please enter a valid phone number",
    "fullName": "Please enter a valid full name first name and last name",
    "walletNumberError": "Please enter a valid Wallet Number",
    "invalidEmail": "Please enter a valid email address",
    "region": "Please select a region",
    "city": "Please select a city",
    "title": "Please enter a valid title",
    "street": "Please enter a valid street"
  },
  "shippingMethods": {
    "deliverWithin": "Delivery within {minDays} - {maxDays} day(s)",
    "forOrders": "for orders before {cutoffTime}",
    "freeOver": " Free Over  "
  },
  "default": "Default",
  "Free": "Free",
  "phone": "Phone",
  "seo": {
    "title": "White Label",
    "description": "White Label"
  },
  "mostPopular": "Most Popular",
  "home": "Home",
  "toBeCalculated": "to be calculated"
}
